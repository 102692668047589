/*
  THIS CSS IS USED AS GLOBAL STYLE, don't remove
*/

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.app-logo {
  /*height: 32px;*/
  /*background: rgba(255,255,255,.2);*/
  margin: 10px;
}
.ant-input-clear-icon {
  color : #ebebeb;
  margin-right: 5px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.trigger:hover {
  color: #1890ff;
}

#anticon-menu-fold .logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}

/* .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
  line-height: 64px;
} */

.ant-table-footer{
  background-color: #fff;
  border-top: 2px solid #e8e8e8;
  margin-top: 2px;
  padding: 5px
}
.ant-table-content{
  /*border: 1px solid #e8e8e8;*/
}
.ant-table-small {
  border: 1px solid #e8e8e8;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

/*.ant-table-small {*/
/*  border:none;*/
/*}*/

.ant-drawer-content-wrapper {
  width: 540px
}
/* @media only screen and (max-width: 1024px) {
  .ant-drawer-content-wrapper {
    width: 80% !important
  }
} */

.ant-select-selection__choice__remove{
  margin-top: 1px;
}

.ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    line-height: 19px;
}

.activeSpan{
  color:blue;
}

.ant-form-extra {
  font-size: 12px;
}

/* OVERIDES */
/* Don't direct override, please use parent class (unique) */
/* .ant-drawer-wrapper-body, .ant-tabs {
  height: calc(100% - 53px) !important;
} */

.ant-drawer-body {
  padding: 12px;
}

.ant-drawer-title {
  margin-left: 2rem;
}

.ant-drawer-close {
  left: 0;
}

/*.ant-drawer-close:hover {*/
/*  color:#ff0000;*/
/*}*/

.ant-drawer-mask{
  /*opacity: 2.3 !important;*/
  cursor: not-allowed;
}

/*.ant-form-item{*/
/*  margin-bottom: 4px !important;*/
/*}*/

/*.ant-drawer-wrapper-body {*/
/*  height: 90% !important*/
/*}*/

/*.ant-drawer-header {*/
/*  position: fixed;*/
/*  background: white;*/
/*  z-index: 99;*/
/*  width: 100%;*/
/*}*/
.ant-table-row {
  cursor: pointer;
}
.ant-drawer-body {
  margin-top: 1rem;
}

.childMenu{
  height: 29px !important;
  line-height: 25px !important;
}

.ant-input-disabled, .ant-select-disabled, .ant-checkbox-disabled, .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice{
  color: rgba(0, 0, 0, 0.70) !important;
}

.custome-form > .ant-form-item-control-wrapper > .ant-form-item-control > .ant-form-item-children{
  display: flex;
}

.custom-icon-collapse {
  margin-right: 12px;
}

.custom-icon-collapse > svg {
  transition: transform .24s,-webkit-transform .24s;
}

.icon-need-hover {
  opacity: 0;
  color: #1890ff;
  transition: opacity 0.3s;
}

.icon-need-hover:hover {
  opacity: 1;
}

.selection-row-item {
  cursor: pointer;
  padding: 6px 12px;
}

.selection-row-item:hover {
  background-color: #e6f7ff;
}

.selection-row-item-disabled {
  cursor: not-allowed;
  background-color: #ccc;
  opacity: 0.5;
}

.hover-danger {
  cursor: pointer;
}

.hover-danger:hover {
  color: #ff0000;
}

.label-error-message {
  color: #f5222d;
  margin-top: 2px;
}

.input-has-error {
  /* { borderColor: '#f5222d', boxShadow: '0 0 0 2px rgba(245, 34, 45, 0.2)' } */
  border-color: #f5222d !important;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2) !important;
}

.header-title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.text-ellipsis {
  max-width: 500px; /* every ellipsis must have limit width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header-content-item {
  /* display: flex !important; */
}

.field-label {
  white-space: normal;
  line-height: 21px;
  padding-top: 8px;
  padding-right: 9px;
}

.focus-no-outline:focus {
  outline: none;
}

.focus-outline-gray:focus {
  outline: #e8e8e8 auto 1px;
}

.white-hover {
  color: rgba(232, 232, 232, 0.7);
}

.white-hover:hover {
  color: #ffffff;
}

/* Override ant design style in here ----------- start */

/* .ant-btn, because when build, line-height changed to 1.499 */
.ant-btn {
  line-height: 1.5;
}

/* .anticon = vertical-align: -0.125em, but in build, got ".ant-btn-icon-only > i" with vertical-align: middle */
.ant-btn-icon-only > i {
  vertical-align: -0.125em;
}

/* override antd style, untuk menutupi angka di job due soon calendar */
.ant-layout-sider-trigger {
  z-index: 10;
}

/* override input number without arrow */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* --------------------------------------------- end */