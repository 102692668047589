.image-hover:hover {
  cursor: zoom-in;
  opacity: 0.8;
}

.z-in {
  cursor: zoom-in;
}

.right {
  float: right;
}

.header {
  margin-bottom: 12px;
}

.flex {
  display: flex;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.overlay:hover {
  opacity: 1;
}

.no-opacity {
  opacity: 0;
}

.css-yrspe {
  z-index: 3 !important;
}

.css-1rbq2qy {
  z-index: 3 !important;
}

.square-width {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.row {
  width: 100%;
}

.equal-container {
  width: 100%;
  height: 100%;
}

.list-item {
  margin-top: 10px;
  padding: 10px;
}

.bordered {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.radius {
  border-radius: 4px;
}

.left-item {
  position: absolute;
  top: 0;
  left: 0;
  padding-right: 12px;
}

.push-left {
  margin-left: 12px !important;
}

.push-right {
  margin-right: 12px;
}

.push-top {
  margin-top: 12px;
}

.push-bottom {
  margin-bottom: 12px;
}

.black {
  color: black;
}

.c-white  {
  color: white !important;
}

.text-1 {
  font-size: 1rem !important;
}

.wrapped {
  white-space: pre-line;
  line-height: 1;
}

.row-item {
  margin: 12px 0 !important;
}

.initial-avatar {
  background-color: #f56a00 !important;
  vertical-align: middle;
}

.absolute-anchor {
  position: absolute;
  top: 0;
  left: 0;
}

.yt-description {
  width: 100%;
  max-height: 55vh !important;
  min-height: 300px;
  overflow: auto;
}

.over-hide {
  overflow: hidden;
}

.img-modal {
  width: 90vw !important;
  max-width: 800px;
}

.sm-modal {
  width: 50vw !important;
  max-width: 800px;
}

.c-gray {
  color: #b3b3b3
}

.c-unselected {
  color: rgba(0, 0, 0, 0.3);
}

.c-selected {
  color: #1890ff;
}

.abs-tl {
  position: absolute;
  top: 0;
  left: 0;
}

.abs-tr {
  position: absolute;
  top: 0;
  right: 0;
}

.abs-br {
  position: absolute;
  bottom: 0;
  right: 0;
}

.gutter-12 {
  margin: 12px;
}

.f-sm {
  font-size: 12px;
}

.bg-transparent {
  background-color: transparent !important;
}

.overlay-item:hover {
  background-color: rgba(125, 125, 125, 0.1)
}

.clicked {
  cursor: pointer;
}

.clicked:hover {
  color: #1890ff;
}

.t-center {
  text-align: center;
}

/* .ck-read-only {
  max-height: 300px;
} */

.ck.ck-editor__main>.ck-editor__editable:not(.ck-read-only) {
  max-height: 300px;
}

.custom-fixed-ckeditor>.ck-editor>.ck.ck-editor__main>.ck-editor__editable:not(.ck-read-only) {
  height: 300px;
}

.ck-read-only>.ck.ck-editor>.ck.ck-editor__main>.ck-read-only {
  height: 100%;
}

.ck-read-only>.ck.ck-editor {
  /* height: 100%; */
  height: 300px;
  /* max-height: 300px; */
  /* overflow: auto; */
}

.ck-read-only>.ck.ck-editor>.ck.ck-editor__main {
  height: 100%;
}

.ant-row.ck-read-only {
  /* height: 100px !important; */
  max-height: 300px;

}
/* class="ck-blurred
ck
ck-content
ck-editor__editable
ck-rounded-corners
ck-editor__editable_inline
ck-read-only" */

.ck.ck-editor__main>.ck-editor__editable {
  background: transparent !important;
}

.header-fixed {
  position: fixed;
  z-index: 1;
  width: 100%;
}

.header-fixed.rm-padding {
  padding: 0 14px;
}

.to-under {
  margin: 14px;
}

.img-fill > img {
  object-fit: fill;
}

::-webkit-scrollbar {
  height: 0.3em;
  width: 0.3em;
  border-radius: 0.3em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
background-color: #68727b;
border-radius: 3px;
/* outline: 1px solid #68727b; */
}

/* antd */
.ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.ant-tabs-ink-bar {
  z-index: 0;
}

.group-left {
  border-radius: 4px 0 0 4px;
}

.group-right>.ant-input, .group-right>.ant-select-selection {
  border-radius: 0 4px 4px 0;
}

/* Style From Pinterest */
.gridCenteredH {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 0px) and (max-width: 575px) {
  .gridCenteredH {
    width: 100px;
  }
  .text-v {
    width: 100px;
    margin: 0 !important;
    padding: 2px !important;
  }
}

@media (min-width: 576) and (max-width: 699px) {
  .gridCenteredH {
    width: 250px;
  }
}

@media (min-width: 700px) and (max-width: 1129px) {
  .gridCenteredH {
    width: 500px;
  }
}

@media (min-width: 1130px) and (max-width: 1399px) {
  .gridCenteredH {
    width: 750px;
  }
}

@media (min-width: 1400px) and (max-width: 1649px) {
  .gridCenteredH {
    width: 1000px;
  }
}

@media (min-width: 1650px) and (max-width: 1899px) {
  .gridCenteredH {
    width: 1250px;
  }
}

@media (min-width: 1900px) and (max-width: 2149px) {
  .gridCenteredH {
    width: 1500px;
  }
}

@media (min-width: 2150px) and (max-width: 2399px) {
  .gridCenteredH {
    width: 1750px;
  }
}

@media (min-width: 2400px) and (max-width: 2649px) {
  .gridCenteredH {
    width: 2000px;
  }
}

@media (min-width: 2650px) and (max-width: 2899px) {
  .gridCenteredH {
    width: 2250px;
  }
}

@media (min-width: 2900px) and (max-width: 3149px) {
  .gridCenteredH {
    width: 2500px;
  }
}

@media (min-width: 3150px) and (max-width: 3399px) {
  .gridCenteredH {
    width: 2750px;
  }
}

/* .gridCentered {
  margin-left: auto;
  margin-right: auto;
} */

/* @media (min-width: 0px) and (max-width: 749px) {
  .gridCentered {
    width: 150px;
  }
  .gridCenteredH {
    width: 150px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+2),
  .gridCentered .static:nth-child(-n+2) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 750px) and (max-width: 1139px) {
  .gridCentered {
    width: 250px;
  }
  .gridCenteredH {
    width: 250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+3),
  .gridCentered .static:nth-child(-n+3) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 1140px) and (max-width: 1249px) {
  .gridCentered {
    width: 500px;
  }
  .gridCenteredH {
    width: 250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+4),
  .gridCentered .static:nth-child(-n+4) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 1250px) and (max-width: 1499px) {
  .gridCentered {
    width: 500px;
  }
  .gridCenteredH {
    width: 500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+5),
  .gridCentered .static:nth-child(-n+5) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 1500px) and (max-width: 1749px) {
  .gridCentered {
    width: 750px;
  }
  .gridCenteredH {
    width: 750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+6),
  .gridCentered .static:nth-child(-n+6) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 1750px) and (max-width: 1999px) {
  .gridCentered {
    width: 1000px;
  }
  .gridCenteredH {
    width: 1000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+7),
  .gridCentered .static:nth-child(-n+7) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 2000px) and (max-width: 2249px) {
  .gridCentered {
    width: 1250px;
  }
  .gridCenteredH {
    width: 1250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+8),
  .gridCentered .static:nth-child(-n+8) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 2250px) and (max-width: 2499px) {
  .gridCentered {
    width: 1500px;
  }
  .gridCenteredH {
    width: 1500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+9),
  .gridCentered .static:nth-child(-n+9) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 2500px) and (max-width: 2749px) {
  .gridCentered {
    width: 1750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+10),
  .gridCentered .static:nth-child(-n+10) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 2750px) and (max-width: 2999px) {
  .gridCentered {
    width: 2000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+11),
  .gridCentered .static:nth-child(-n+11) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 3000px) and (max-width: 3249px) {
  .gridCentered {
    width: 2250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+12),
  .gridCentered .static:nth-child(-n+12) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 3250px) and (max-width: 3499px) {
  .gridCentered {
    width: 2500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+13),
  .gridCentered .static:nth-child(-n+13) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
} */

/* @media (min-width: 3500px) and (max-width: 3749px) {
  .gridCentered {
    width: 3500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+14),
  .gridCentered .static:nth-child(-n+14) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 3750px) and (max-width: 3999px) {
  .gridCentered {
    width: 3750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+15),
  .gridCentered .static:nth-child(-n+15) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 4000px) and (max-width: 4249px) {
  .gridCentered {
    width: 4000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+16),
  .gridCentered .static:nth-child(-n+16) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 4250px) and (max-width: 4499px) {
  .gridCentered {
    width: 4250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+17),
  .gridCentered .static:nth-child(-n+17) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 4500px) and (max-width: 4749px) {
  .gridCentered {
    width: 4500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+18),
  .gridCentered .static:nth-child(-n+18) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 4750px) and (max-width: 4999px) {
  .gridCentered {
    width: 4750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+19),
  .gridCentered .static:nth-child(-n+19) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 5000px) and (max-width: 5249px) {
  .gridCentered {
    width: 5000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+20),
  .gridCentered .static:nth-child(-n+20) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 5250px) and (max-width: 5499px) {
  .gridCentered {
    width: 5250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+21),
  .gridCentered .static:nth-child(-n+21) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 5500px) and (max-width: 5749px) {
  .gridCentered {
    width: 5500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+22),
  .gridCentered .static:nth-child(-n+22) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 5750px) and (max-width: 5999px) {
  .gridCentered {
    width: 5750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+23),
  .gridCentered .static:nth-child(-n+23) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 6000px) and (max-width: 6249px) {
  .gridCentered {
    width: 6000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+24),
  .gridCentered .static:nth-child(-n+24) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 6250px) and (max-width: 6499px) {
  .gridCentered {
    width: 6250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+25),
  .gridCentered .static:nth-child(-n+25) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 6500px) and (max-width: 6749px) {
  .gridCentered {
    width: 6500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+26),
  .gridCentered .static:nth-child(-n+26) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 6750px) and (max-width: 6999px) {
  .gridCentered {
    width: 6750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+27),
  .gridCentered .static:nth-child(-n+27) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 7000px) and (max-width: 7249px) {
  .gridCentered {
    width: 7000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+28),
  .gridCentered .static:nth-child(-n+28) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 7250px) and (max-width: 7499px) {
  .gridCentered {
    width: 7250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+29),
  .gridCentered .static:nth-child(-n+29) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 7500px) and (max-width: 7749px) {
  .gridCentered {
    width: 7500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+30),
  .gridCentered .static:nth-child(-n+30) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 7750px) and (max-width: 7999px) {
  .gridCentered {
    width: 7750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+31),
  .gridCentered .static:nth-child(-n+31) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 8000px) and (max-width: 8249px) {
  .gridCentered {
    width: 8000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+32),
  .gridCentered .static:nth-child(-n+32) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 8250px) and (max-width: 8499px) {
  .gridCentered {
    width: 8250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+33),
  .gridCentered .static:nth-child(-n+33) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 8500px) and (max-width: 8749px) {
  .gridCentered {
    width: 8500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+34),
  .gridCentered .static:nth-child(-n+34) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 8750px) and (max-width: 8999px) {
  .gridCentered {
    width: 8750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+35),
  .gridCentered .static:nth-child(-n+35) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 9000px) and (max-width: 9249px) {
  .gridCentered {
    width: 9000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+36),
  .gridCentered .static:nth-child(-n+36) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 9250px) and (max-width: 9499px) {
  .gridCentered {
    width: 9250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+37),
  .gridCentered .static:nth-child(-n+37) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 9500px) and (max-width: 9749px) {
  .gridCentered {
    width: 9500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+38),
  .gridCentered .static:nth-child(-n+38) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 9750px) and (max-width: 9999px) {
  .gridCentered {
    width: 9750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+39),
  .gridCentered .static:nth-child(-n+39) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 10000px) and (max-width: 10249px) {
  .gridCentered {
    width: 10000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+40),
  .gridCentered .static:nth-child(-n+40) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 10250px) and (max-width: 10499px) {
  .gridCentered {
    width: 10250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+41),
  .gridCentered .static:nth-child(-n+41) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 10500px) and (max-width: 10749px) {
  .gridCentered {
    width: 10500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+42),
  .gridCentered .static:nth-child(-n+42) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 10750px) and (max-width: 10999px) {
  .gridCentered {
    width: 10750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+43),
  .gridCentered .static:nth-child(-n+43) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 11000px) and (max-width: 11249px) {
  .gridCentered {
    width: 11000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+44),
  .gridCentered .static:nth-child(-n+44) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
} */