.reset-card {
  background-color: #fff;
  width: 100%;
}
.section-card {
  padding: 24px;
}
.section-card-left {
  width: 100%;
}
.section-card-right {
  display: none;
}
@media (min-width: 768px) {
  .reset-card {
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    width: 700px;
  }
  .section-card-left {
    width: 50%;
  }
  .section-card-right {
    display: block;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .section-card {
    padding: 48px 24px;
  }
  .reset-card {
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    width: 900px;
  }
}

.image-hover:hover {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  opacity: 0.8;
}

.z-in {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.right {
  float: right;
}

.header {
  margin-bottom: 12px;
}

.flex {
  display: flex;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.overlay:hover {
  opacity: 1;
}

.no-opacity {
  opacity: 0;
}

.css-yrspe {
  z-index: 3 !important;
}

.css-1rbq2qy {
  z-index: 3 !important;
}

.square-width {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.row {
  width: 100%;
}

.equal-container {
  width: 100%;
  height: 100%;
}

.list-item {
  margin-top: 10px;
  padding: 10px;
}

.bordered {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.radius {
  border-radius: 4px;
}

.left-item {
  position: absolute;
  top: 0;
  left: 0;
  padding-right: 12px;
}

.push-left {
  margin-left: 12px !important;
}

.push-right {
  margin-right: 12px;
}

.push-top {
  margin-top: 12px;
}

.push-bottom {
  margin-bottom: 12px;
}

.black {
  color: black;
}

.c-white  {
  color: white !important;
}

.text-1 {
  font-size: 1rem !important;
}

.wrapped {
  white-space: pre-line;
  line-height: 1;
}

.row-item {
  margin: 12px 0 !important;
}

.initial-avatar {
  background-color: #f56a00 !important;
  vertical-align: middle;
}

.absolute-anchor {
  position: absolute;
  top: 0;
  left: 0;
}

.yt-description {
  width: 100%;
  max-height: 55vh !important;
  min-height: 300px;
  overflow: auto;
}

.over-hide {
  overflow: hidden;
}

.img-modal {
  width: 90vw !important;
  max-width: 800px;
}

.sm-modal {
  width: 50vw !important;
  max-width: 800px;
}

.c-gray {
  color: #b3b3b3
}

.c-unselected {
  color: rgba(0, 0, 0, 0.3);
}

.c-selected {
  color: #1890ff;
}

.abs-tl {
  position: absolute;
  top: 0;
  left: 0;
}

.abs-tr {
  position: absolute;
  top: 0;
  right: 0;
}

.abs-br {
  position: absolute;
  bottom: 0;
  right: 0;
}

.gutter-12 {
  margin: 12px;
}

.f-sm {
  font-size: 12px;
}

.bg-transparent {
  background-color: transparent !important;
}

.overlay-item:hover {
  background-color: rgba(125, 125, 125, 0.1)
}

.clicked {
  cursor: pointer;
}

.clicked:hover {
  color: #1890ff;
}

.t-center {
  text-align: center;
}

/* .ck-read-only {
  max-height: 300px;
} */

.ck.ck-editor__main>.ck-editor__editable:not(.ck-read-only) {
  max-height: 300px;
}

.custom-fixed-ckeditor>.ck-editor>.ck.ck-editor__main>.ck-editor__editable:not(.ck-read-only) {
  height: 300px;
}

.ck-read-only>.ck.ck-editor>.ck.ck-editor__main>.ck-read-only {
  height: 100%;
}

.ck-read-only>.ck.ck-editor {
  /* height: 100%; */
  height: 300px;
  /* max-height: 300px; */
  /* overflow: auto; */
}

.ck-read-only>.ck.ck-editor>.ck.ck-editor__main {
  height: 100%;
}

.ant-row.ck-read-only {
  /* height: 100px !important; */
  max-height: 300px;

}
/* class="ck-blurred
ck
ck-content
ck-editor__editable
ck-rounded-corners
ck-editor__editable_inline
ck-read-only" */

.ck.ck-editor__main>.ck-editor__editable {
  background: transparent !important;
}

.header-fixed {
  position: fixed;
  z-index: 1;
  width: 100%;
}

.header-fixed.rm-padding {
  padding: 0 14px;
}

.to-under {
  margin: 14px;
}

.img-fill > img {
  object-fit: fill;
}

::-webkit-scrollbar {
  height: 0.3em;
  width: 0.3em;
  border-radius: 0.3em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
background-color: #68727b;
border-radius: 3px;
/* outline: 1px solid #68727b; */
}

/* antd */
.ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.ant-tabs-ink-bar {
  z-index: 0;
}

.group-left {
  border-radius: 4px 0 0 4px;
}

.group-right>.ant-input, .group-right>.ant-select-selection {
  border-radius: 0 4px 4px 0;
}

/* Style From Pinterest */
.gridCenteredH {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 0px) and (max-width: 575px) {
  .gridCenteredH {
    width: 100px;
  }
  .text-v {
    width: 100px;
    margin: 0 !important;
    padding: 2px !important;
  }
}

@media (min-width: 576) and (max-width: 699px) {
  .gridCenteredH {
    width: 250px;
  }
}

@media (min-width: 700px) and (max-width: 1129px) {
  .gridCenteredH {
    width: 500px;
  }
}

@media (min-width: 1130px) and (max-width: 1399px) {
  .gridCenteredH {
    width: 750px;
  }
}

@media (min-width: 1400px) and (max-width: 1649px) {
  .gridCenteredH {
    width: 1000px;
  }
}

@media (min-width: 1650px) and (max-width: 1899px) {
  .gridCenteredH {
    width: 1250px;
  }
}

@media (min-width: 1900px) and (max-width: 2149px) {
  .gridCenteredH {
    width: 1500px;
  }
}

@media (min-width: 2150px) and (max-width: 2399px) {
  .gridCenteredH {
    width: 1750px;
  }
}

@media (min-width: 2400px) and (max-width: 2649px) {
  .gridCenteredH {
    width: 2000px;
  }
}

@media (min-width: 2650px) and (max-width: 2899px) {
  .gridCenteredH {
    width: 2250px;
  }
}

@media (min-width: 2900px) and (max-width: 3149px) {
  .gridCenteredH {
    width: 2500px;
  }
}

@media (min-width: 3150px) and (max-width: 3399px) {
  .gridCenteredH {
    width: 2750px;
  }
}

/* .gridCentered {
  margin-left: auto;
  margin-right: auto;
} */

/* @media (min-width: 0px) and (max-width: 749px) {
  .gridCentered {
    width: 150px;
  }
  .gridCenteredH {
    width: 150px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+2),
  .gridCentered .static:nth-child(-n+2) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 750px) and (max-width: 1139px) {
  .gridCentered {
    width: 250px;
  }
  .gridCenteredH {
    width: 250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+3),
  .gridCentered .static:nth-child(-n+3) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 1140px) and (max-width: 1249px) {
  .gridCentered {
    width: 500px;
  }
  .gridCenteredH {
    width: 250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+4),
  .gridCentered .static:nth-child(-n+4) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 1250px) and (max-width: 1499px) {
  .gridCentered {
    width: 500px;
  }
  .gridCenteredH {
    width: 500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+5),
  .gridCentered .static:nth-child(-n+5) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 1500px) and (max-width: 1749px) {
  .gridCentered {
    width: 750px;
  }
  .gridCenteredH {
    width: 750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+6),
  .gridCentered .static:nth-child(-n+6) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 1750px) and (max-width: 1999px) {
  .gridCentered {
    width: 1000px;
  }
  .gridCenteredH {
    width: 1000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+7),
  .gridCentered .static:nth-child(-n+7) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 2000px) and (max-width: 2249px) {
  .gridCentered {
    width: 1250px;
  }
  .gridCenteredH {
    width: 1250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+8),
  .gridCentered .static:nth-child(-n+8) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 2250px) and (max-width: 2499px) {
  .gridCentered {
    width: 1500px;
  }
  .gridCenteredH {
    width: 1500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+9),
  .gridCentered .static:nth-child(-n+9) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 2500px) and (max-width: 2749px) {
  .gridCentered {
    width: 1750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+10),
  .gridCentered .static:nth-child(-n+10) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 2750px) and (max-width: 2999px) {
  .gridCentered {
    width: 2000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+11),
  .gridCentered .static:nth-child(-n+11) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 3000px) and (max-width: 3249px) {
  .gridCentered {
    width: 2250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+12),
  .gridCentered .static:nth-child(-n+12) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 3250px) and (max-width: 3499px) {
  .gridCentered {
    width: 2500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+13),
  .gridCentered .static:nth-child(-n+13) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
} */

/* @media (min-width: 3500px) and (max-width: 3749px) {
  .gridCentered {
    width: 3500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+14),
  .gridCentered .static:nth-child(-n+14) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 3750px) and (max-width: 3999px) {
  .gridCentered {
    width: 3750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+15),
  .gridCentered .static:nth-child(-n+15) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 4000px) and (max-width: 4249px) {
  .gridCentered {
    width: 4000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+16),
  .gridCentered .static:nth-child(-n+16) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 4250px) and (max-width: 4499px) {
  .gridCentered {
    width: 4250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+17),
  .gridCentered .static:nth-child(-n+17) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 4500px) and (max-width: 4749px) {
  .gridCentered {
    width: 4500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+18),
  .gridCentered .static:nth-child(-n+18) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 4750px) and (max-width: 4999px) {
  .gridCentered {
    width: 4750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+19),
  .gridCentered .static:nth-child(-n+19) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 5000px) and (max-width: 5249px) {
  .gridCentered {
    width: 5000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+20),
  .gridCentered .static:nth-child(-n+20) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 5250px) and (max-width: 5499px) {
  .gridCentered {
    width: 5250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+21),
  .gridCentered .static:nth-child(-n+21) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 5500px) and (max-width: 5749px) {
  .gridCentered {
    width: 5500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+22),
  .gridCentered .static:nth-child(-n+22) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 5750px) and (max-width: 5999px) {
  .gridCentered {
    width: 5750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+23),
  .gridCentered .static:nth-child(-n+23) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 6000px) and (max-width: 6249px) {
  .gridCentered {
    width: 6000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+24),
  .gridCentered .static:nth-child(-n+24) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 6250px) and (max-width: 6499px) {
  .gridCentered {
    width: 6250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+25),
  .gridCentered .static:nth-child(-n+25) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 6500px) and (max-width: 6749px) {
  .gridCentered {
    width: 6500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+26),
  .gridCentered .static:nth-child(-n+26) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 6750px) and (max-width: 6999px) {
  .gridCentered {
    width: 6750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+27),
  .gridCentered .static:nth-child(-n+27) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 7000px) and (max-width: 7249px) {
  .gridCentered {
    width: 7000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+28),
  .gridCentered .static:nth-child(-n+28) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 7250px) and (max-width: 7499px) {
  .gridCentered {
    width: 7250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+29),
  .gridCentered .static:nth-child(-n+29) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 7500px) and (max-width: 7749px) {
  .gridCentered {
    width: 7500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+30),
  .gridCentered .static:nth-child(-n+30) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 7750px) and (max-width: 7999px) {
  .gridCentered {
    width: 7750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+31),
  .gridCentered .static:nth-child(-n+31) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 8000px) and (max-width: 8249px) {
  .gridCentered {
    width: 8000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+32),
  .gridCentered .static:nth-child(-n+32) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 8250px) and (max-width: 8499px) {
  .gridCentered {
    width: 8250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+33),
  .gridCentered .static:nth-child(-n+33) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 8500px) and (max-width: 8749px) {
  .gridCentered {
    width: 8500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+34),
  .gridCentered .static:nth-child(-n+34) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 8750px) and (max-width: 8999px) {
  .gridCentered {
    width: 8750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+35),
  .gridCentered .static:nth-child(-n+35) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 9000px) and (max-width: 9249px) {
  .gridCentered {
    width: 9000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+36),
  .gridCentered .static:nth-child(-n+36) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 9250px) and (max-width: 9499px) {
  .gridCentered {
    width: 9250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+37),
  .gridCentered .static:nth-child(-n+37) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 9500px) and (max-width: 9749px) {
  .gridCentered {
    width: 9500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+38),
  .gridCentered .static:nth-child(-n+38) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 9750px) and (max-width: 9999px) {
  .gridCentered {
    width: 9750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+39),
  .gridCentered .static:nth-child(-n+39) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 10000px) and (max-width: 10249px) {
  .gridCentered {
    width: 10000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+40),
  .gridCentered .static:nth-child(-n+40) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 10250px) and (max-width: 10499px) {
  .gridCentered {
    width: 10250px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+41),
  .gridCentered .static:nth-child(-n+41) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 10500px) and (max-width: 10749px) {
  .gridCentered {
    width: 10500px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+42),
  .gridCentered .static:nth-child(-n+42) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 10750px) and (max-width: 10999px) {
  .gridCentered {
    width: 10750px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+43),
  .gridCentered .static:nth-child(-n+43) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
}

@media (min-width: 11000px) and (max-width: 11249px) {
  .gridCentered {
    width: 11000px;
  }
  .gridCentered .Masonry-Premount .Collection-Item:nth-child(-n+44),
  .gridCentered .static:nth-child(-n+44) {
    position: static !important;
    visibility: visible !important;
    float: left;
    display: block;
  }
} */
.one-line {
  white-space: nowrap;
}

/*
  THIS CSS IS USED AS GLOBAL STYLE, don't remove
*/

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.app-logo {
  /*height: 32px;*/
  /*background: rgba(255,255,255,.2);*/
  margin: 10px;
}
.ant-input-clear-icon {
  color : #ebebeb;
  margin-right: 5px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  -webkit-transition: color .3s;
  transition: color .3s;
}

.trigger:hover {
  color: #1890ff;
}

#anticon-menu-fold .logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}

/* .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
  line-height: 64px;
} */

.ant-table-footer{
  background-color: #fff;
  border-top: 2px solid #e8e8e8;
  margin-top: 2px;
  padding: 5px
}
.ant-table-content{
  /*border: 1px solid #e8e8e8;*/
}
.ant-table-small {
  border: 1px solid #e8e8e8;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

/*.ant-table-small {*/
/*  border:none;*/
/*}*/

.ant-drawer-content-wrapper {
  width: 540px
}
/* @media only screen and (max-width: 1024px) {
  .ant-drawer-content-wrapper {
    width: 80% !important
  }
} */

.ant-select-selection__choice__remove{
  margin-top: 1px;
}

.ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    line-height: 19px;
}

.activeSpan{
  color:blue;
}

.ant-form-extra {
  font-size: 12px;
}

/* OVERIDES */
/* Don't direct override, please use parent class (unique) */
/* .ant-drawer-wrapper-body, .ant-tabs {
  height: calc(100% - 53px) !important;
} */

.ant-drawer-body {
  padding: 12px;
}

.ant-drawer-title {
  margin-left: 2rem;
}

.ant-drawer-close {
  left: 0;
}

/*.ant-drawer-close:hover {*/
/*  color:#ff0000;*/
/*}*/

.ant-drawer-mask{
  /*opacity: 2.3 !important;*/
  cursor: not-allowed;
}

/*.ant-form-item{*/
/*  margin-bottom: 4px !important;*/
/*}*/

/*.ant-drawer-wrapper-body {*/
/*  height: 90% !important*/
/*}*/

/*.ant-drawer-header {*/
/*  position: fixed;*/
/*  background: white;*/
/*  z-index: 99;*/
/*  width: 100%;*/
/*}*/
.ant-table-row {
  cursor: pointer;
}
.ant-drawer-body {
  margin-top: 1rem;
}

.childMenu{
  height: 29px !important;
  line-height: 25px !important;
}

.ant-input-disabled, .ant-select-disabled, .ant-checkbox-disabled, .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice{
  color: rgba(0, 0, 0, 0.70) !important;
}

.custome-form > .ant-form-item-control-wrapper > .ant-form-item-control > .ant-form-item-children{
  display: flex;
}

.custom-icon-collapse {
  margin-right: 12px;
}

.custom-icon-collapse > svg {
  transition: transform .24s,-webkit-transform .24s;
}

.icon-need-hover {
  opacity: 0;
  color: #1890ff;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.icon-need-hover:hover {
  opacity: 1;
}

.selection-row-item {
  cursor: pointer;
  padding: 6px 12px;
}

.selection-row-item:hover {
  background-color: #e6f7ff;
}

.selection-row-item-disabled {
  cursor: not-allowed;
  background-color: #ccc;
  opacity: 0.5;
}

.hover-danger {
  cursor: pointer;
}

.hover-danger:hover {
  color: #ff0000;
}

.label-error-message {
  color: #f5222d;
  margin-top: 2px;
}

.input-has-error {
  /* { borderColor: '#f5222d', boxShadow: '0 0 0 2px rgba(245, 34, 45, 0.2)' } */
  border-color: #f5222d !important;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2) !important;
}

.header-title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.text-ellipsis {
  max-width: 500px; /* every ellipsis must have limit width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header-content-item {
  /* display: flex !important; */
}

.field-label {
  white-space: normal;
  line-height: 21px;
  padding-top: 8px;
  padding-right: 9px;
}

.focus-no-outline:focus {
  outline: none;
}

.focus-outline-gray:focus {
  outline: #e8e8e8 auto 1px;
}

.white-hover {
  color: rgba(232, 232, 232, 0.7);
}

.white-hover:hover {
  color: #ffffff;
}

/* Override ant design style in here ----------- start */

/* .ant-btn, because when build, line-height changed to 1.499 */
.ant-btn {
  line-height: 1.5;
}

/* .anticon = vertical-align: -0.125em, but in build, got ".ant-btn-icon-only > i" with vertical-align: middle */
.ant-btn-icon-only > i {
  vertical-align: -0.125em;
}

/* override antd style, untuk menutupi angka di job due soon calendar */
.ant-layout-sider-trigger {
  z-index: 10;
}

/* override input number without arrow */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* --------------------------------------------- end */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.formTable .ant-form-item {
  margin-bottom: 0;
}
#divList:hover {
  background-color: #E3E3E3;
  color: #1890ff;
}
.dot {
  shape-outside: margin-box;
  height: 5px;
  width: 5px;
  background-color: #403F3F;
  border-radius: 50%;
  display: inline-block;
  margin-top: 11px;
  margin-left: 7px;
}
.theadtabel .ant-table-thead {
  display: none;
}
.theadtabel .ant-table-small {
  border: transparent;
}
.theadtabel .ant-table-row-expand-icon-cell {
  width: 0%;
}
.expantable .ant-table-expand-icon-th {
  width: 0%;
}
.modal-rule .ant-modal {
  width: 900px;
}
.drawer-Rule .ant-drawer-title {
  margin-left: 0rem;
}
.tabcard .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  padding: 0px 0px 0px 9px;
}
.rules .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 0px 0px 0px 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 12px;
  border-bottom: 1px solid;
  border-radius: 0;
}
.rules .ant-collapse .ant-collapse-item {
  border: transparent;
}
.rules .ant-collapse {
  border: 1px solid #fffefe;
}
.rules .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}
.deviderbutton .ant-btn-circle,
.deviderbutton .ant-btn-circle-outline {
  min-width: 20px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.deviderbutton .ant-btn-icon-only {
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 11px;
  border-radius: 4px;
}
.deviderbutton .ant-divider-horizontal.ant-divider-with-text-left,
.deviderbutton .ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  margin: -6px 0px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 50;
  font-size: 13px;
  white-space: nowrap;
  text-align: center;
  background: transparent;
}
.collap .ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", "tnum";
  background-color: transparent;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 0px;
}
.collap .ant-collapse-header {
  position: relative;
  padding: 6px 4px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.avatarlist .ant-avatar-sm {
  width: 19px;
  height: 19px;
  line-height: 19px;
  border-radius: 50%;
  background-color: transparent;
}
.avatarlist .ant-list-bordered.ant-list-sm .ant-list-item {
  padding-left: 11px;
  padding-top: 4px;
}
.avatarlist .ant-list-item-meta-avatar {
  margin-right: 0;
}
.avatarlist .anticon {
  font-size: 11px;
  color: #1890ff;
}
.avatarlist .ant-list-item-meta-title {
  margin-bottom: -2px;
}
ul.a {
  list-style-position: outside;
  margin: 3px;
  font-size: 12px;
}
.buttonTrue {
  color: black;
}
.buttonFalse {
  color: black;
}
.flexx {
  display: flex;
}
.keyword {
  display: block;
}
.keyword-title {
  width: 100%;
}
.four {
  flex: 4 1;
}
@media only screen and (max-width: 1000px) {
  .flexx {
    display: block;
  }
  .four {
    width: 100%;
  }
}
.custom {
  padding: 3px, 16px;
}
.custom .ant-collapse-content-box {
  padding: 0;
}
.custom .ant-collapse-header {
  font-size: 12px;
}
.tablell .ant-table-tbody {
  font-size: 11px;
}
.tablell .ant-table-thead {
  background-color: ghostwhite;
}
.listtt .ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 0;
  padding-left: 0;
}
.listtt .ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 0px;
  padding-left: 0px;
}
.listtt .ant-list-sm .ant-list-item {
  padding-top: 0px;
  padding-bottom: 1px;
}
.listtt .ant-list-item {
  padding: 0;
}
.panelll .ant-collapse .ant-collapse-item .ant-collapse-header {
  padding: 3px 16px;
  padding-left: 37px;
}
.panelll .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
}
.radioku .ant-radio-group {
  margin: 18px;
}
.labell .ant-form-extra {
  font-size: 12px;
  margin-left: 7%;
}
.labell .ant-form-item-label {
  text-align: left;
  width: 100%;
  font-weight: 500;
}
.labell .ant-col-sm-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
  width: 96%;
}
.labell .ant-form-item-control {
  position: relative;
  line-height: 40px;
  zoom: 1;
  width: 100%;
}
.butn .ant-btn {
  font-size: 10px;
  height: 24px;
  white-space: normal;
  text-align: left;
  padding: 0 -23px;
}
.bodycar .ant-card-body {
  padding-top: 0px;
}
.btnClass {
  background-color: red;
}
.btnClass.clicked {
  background-color: green;
}
.heads .ant-card-head {
  color: cornflowerblue;
  min-height: 38px;
}
.heads .ant-collapse-borderless .ant-collapse-item {
  background-color: white;
}
.heads .ant-card-extra {
  padding: 0;
}
.heads .ant-card {
  line-height: 1;
}
.heads .ant-card-bordered {
  /* border: 1px solid #d9c5c5; */
  border-radius: 0px;
}
.heads .ant-card-type-inner .ant-card-head-title {
  padding: 7px 0;
  font-size: 13px;
}
.heads .ant-tabs-bar {
  margin: 0 0 8px 0;
}
.item .ant-list-sm .ant-list-item {
  padding-top: 0px;
  padding-bottom: 1px;
}
.item .ant-list-item {
  padding: 0;
}
.GgvQZ {
  background: #ffffff;
  padding: 3.4rem;
  border-radius: 4px;
}
.spin-filter i:hover {
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out;
  color: #000000 !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-10 {
  width: 10% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.min-w-0 {
  min-width: 0 !important;
}
.min-w-1 {
  min-width: 3rem !important;
}
.min-w-3 {
  min-width: 8.5rem !important;
}
.min-w-4 {
  min-width: 12rem !important;
}
.min-w-5 {
  min-width: 15rem !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-2b {
  margin: 0.75rem !important;
}
.mt-2b {
  margin-top: 0.75rem !important;
}
.mr-2b {
  margin-right: 0.75rem !important;
}
.mb-2b {
  margin-bottom: 0.75rem !important;
}
.ml-2b {
  margin-left: 0.75rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.mt-m3 {
  margin-top: -1rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-italic {
  font-style: italic !important;
}
.font-xs {
  font-size: 0.65rem !important;
}
.font-sm {
  font-size: 0.85rem !important;
}
.font-md {
  font-size: 0.9rem !important;
}
.font-lg {
  font-size: 1rem !important;
}
.font-xl {
  font-size: 1.25rem !important;
}
.font-2xl {
  font-size: 1.5rem !important;
}
.font-3xl {
  font-size: 1.75rem !important;
}
.font-4xl {
  font-size: 2rem !important;
}
.font-5xl {
  font-size: 2.5rem !important;
}
.text-value {
  font-size: 1.3125rem;
  font-weight: 600;
}
.text-value-sm {
  font-size: 1.09375rem;
  font-weight: 600;
}
.text-value-lg {
  font-size: 1.53125rem;
  font-weight: 600;
}
.text-white .text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #20a8d8 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #1985ac !important;
}
.text-secondary {
  color: #c8ced3 !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #acb5bc !important;
}
.text-success {
  color: #4dbd74 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #3a9d5d !important;
}
.text-info {
  color: #63c2de !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #39b2d5 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #d39e00 !important;
}
.text-danger {
  color: #f86c6b !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #f63c3a !important;
}
.text-light {
  color: #f0f3f5 !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #d1dbe1 !important;
}
.text-dark {
  color: #2f353a !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #181b1e !important;
}
.text-body {
  color: #23282c !important;
}
.text-muted {
  color: #73818f !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.border {
  border: 1px solid #c8ced3 !important;
}
.border-top {
  border-top: 1px solid #c8ced3 !important;
}
.border-right {
  border-right: 1px solid #c8ced3 !important;
}
.border-bottom {
  border-bottom: 1px solid #c8ced3 !important;
}
.border-left {
  border-left: 1px solid #c8ced3 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #20a8d8 !important;
}
.border-secondary {
  border-color: #c8ced3 !important;
}
.border-success {
  border-color: #4dbd74 !important;
}
.border-info {
  border-color: #63c2de !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #f86c6b !important;
}
.border-light {
  border-color: #f0f3f5 !important;
}
.border-dark {
  border-color: #2f353a !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-10 {
  border-radius: 10% !important;
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
.lh-0 {
  line-height: 0 !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-2 {
  line-height: 2 !important;
}
.bg-none {
  background: none !important;
}
.bg-aliceblue {
  background: aliceblue !important;
}
.bg-cornsilk {
  background: cornsilk !important;
}
.bg-white {
  background: white !important;
}
.bg-tomato {
  background-color: tomato !important;
}
.bg-beige {
  background: beige !important;
}
.c-azure {
  color: azure !important;
}
.c-cadetblue {
  color: cadetblue !important;
}
.c-aqua {
  color: aqua !important;
}
.c-coral {
  color: coral !important;
}
.c-red {
  color: red !important;
}
.c-lime {
  color: lime !important;
}
/* =================================================================== */
/* user management */
.button-area {
  padding: 8px 14px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.animated.hinge {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
.ant-desc-custom td {
  padding: 0.25rem !important;
}
.ant-desc-custom .ant-descriptions-item-label {
  color: #73818f !important;
}
.ant-desc-custom .ant-descriptions-item-content {
  font-weight: 700 !important;
}
.ant-transfer-list-40 .ant-transfer-list {
  width: 40% !important;
}
.ant-transfer-list-50 .ant-transfer-list {
  width: calc(50% - 22px);
  height: 365px;
}
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
.page-loading-indicator {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: white !important;
  border-radius: 10% !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.list-data-panel {
  background: #f7f7f7;
  overflow: hidden;
  border-radius: 0.25rem !important;
  border: 0 !important;
  margin-top: 0.5rem !important;
}
.list-data-control-box {
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  padding: 1rem !important;
  border-radius: 0.25rem !important;
}
.ug-header {
  background: white !important;
  height: auto !important;
  border-bottom: 1px solid #e8e8e8;
}
.ug-header .ant-menu-horizontal {
  border-bottom: 0 !important;
}
.um-layout {
  min-height: 100vh !important;
}
.um-sider {
  overflow: auto;
  left: 0;
}
.um-avatar-container {
  background: #000c17;
}
.um-avatar-border {
  border: 0.25rem solid #1d293e;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.um-avatar-name {
  color: azure !important;
  text-align: center !important;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.um-avatar-icon {
  color: cadetblue !important;
  font-size: 1rem !important;
}
.um-avatar-count {
  color: aqua !important;
}
.um-profile-tabs .ant-tabs-tab {
  text-align: left !important;
}
.um-branch-groups-caption {
  border-left: 3px solid darksalmon;
  padding-left: 0.5rem !important;
  background: beige !important;
}
.um-branch-home-permission .ant-switch-checked {
  background-color: tomato !important;
}
.um-branch-visiting-table .ant-switch-checked {
  background-color: tomato !important;
}
.um-branch-visiting-table .ant-table {
  overflow-x: auto !important;
}
/* end user management */
.column-sorter-x {
  display: table-cell;
  vertical-align: middle;
}
.sorter-inner-x {
  height: 1em;
  margin-left: 0.57142857em;
  color: #bfbfbf;
  line-height: 0.5em;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.sorter-up-x {
  height: 0.5em;
  line-height: 1em;
  display: block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.sorter-down-x {
  margin-top: 0.125em;
  height: 1em;
  line-height: 0.5em;
  -webkit-transform: scale(0.91666667) rotate(0deg);
          transform: scale(0.91666667) rotate(0deg);
  display: block;
}
.col-ellipsis-x {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
}
.arrow-left {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 10px solid #50a6f5;
}
.card-shadow {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}
.job-list-file-item:hover .job-file-upload {
  display: inline-block !important;
}
.job-title {
  display: inline-block;
  font-weight: 500;
  font-size: 1.75rem;
  font-family: Nunito, sans-serif;
  line-height: 1.2;
}
.job-title-tab > .ant-tabs-bar {
  background: #fff;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.job-history-card {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}
.job-history-card .ant-card-body {
  padding: 0.5rem !important;
}
.job-history-card .ant-card-head {
  font-size: 14px;
  min-height: 0;
  padding: 0.25rem !important;
}
.job-history-card .ant-card-head-title {
  background-color: #ffffff;
  padding: 0.5rem !important;
}
.job-history-card .field {
  color: currentColor;
  font-weight: bolder;
}
.job-history-card .from {
  color: #20a8d8;
  font-weight: bolder;
}
.job-history-card .to {
  color: coral;
  font-weight: bolder;
}
.job-action-radio {
  display: block !important;
  height: 30px;
  line-height: 30px !important;
}
.job-action-title {
  background-color: gainsboro;
  padding: 0.25rem !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
}
.job-page-header .ant-page-header-content {
  padding-top: 1.5rem !important;
}
.job-file-upload {
  display: none;
}
.job-extra-charge-title {
  background-color: #008fff;
  text-align: center !important;
  color: #ffffff;
  font-weight: 700 !important;
}
.job-extra-charge-body {
  background: whitesmoke;
  line-height: 2 !important;
}
.job-tag-options {
  overflow: auto scroll;
  overflow-x: auto;
  overflow-y: scroll;
  max-height: 10rem;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.select-all {
  cursor: pointer;
  font-size: 10px;
  display: inline-block;
}
.try-hover-border:hover {
  border: 1px solid #0000ff;
}
.try-hover-border2:hover {
  border: 1px solid #ff0000;
}
.margin-selection_choice_remove.ant-select-sm .ant-select-selection__choice__remove {
  margin-top: 0;
}
.margin-selection_choice_remove:not(.ant-select-sm) .ant-select-selection--multiple .ant-select-selection__choice__remove {
  margin-top: 1px;
}
.ant-collapse-header-1 .ant-collapse-header {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.hover-calendar {
  cursor: pointer;
}
.group-title {
  color: #00000073;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
.ant-tabs-top.custom-margin-tab .ant-tabs-nav .ant-tabs-tab:first-child {
  margin-left: 12px;
}
.ant-tabs-top.custom-margin-tab .ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 12px;
}
.ant-tabs-top.custom-margin-tab .ant-tabs-vertical .ant-tabs-nav .ant-tabs-tab:first-child {
  margin-left: 0;
}
.ant-tabs-top.custom-margin-tab .ant-tabs-vertical .ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 0;
}
.custom-popover-content .ant-popover-inner-content {
  padding: 0;
}
.custom-menu-item {
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5rem !important;
}
.custom-menu-item:hover {
  background-color: #e6f7ff;
}
.custom-menu-item.inactive {
  background-color: #e8e8e88c;
  color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}
.custom-draggable-item {
  background-color: white;
  border: 1px solid #ebecf0;
  border-radius: 2px;
  padding: 8px;
}
.custom-draggable-item.selected {
  background-color: #e6f7ff;
  border: 1px solid #1890ff;
}
.child-border-right:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
.group-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}
.group-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}
.child-hover {
  display: none;
}
.child-hover:hover {
  background-color: rgba(224, 224, 224, 0.65);
}
.container-hover:hover > .child-hover {
  display: inline-block;
}
.hovered-gray:hover {
  background-color: rgba(224, 224, 224, 0.65);
  cursor: pointer;
}
.custom-available-list {
  height: calc(80vh - 340px - 21px - 29px);
  min-height: 150px;
  overflow-y: auto;
}
.test-custom-search {
  opacity: 0.3;
}
.test-custom-search:hover,
.test-custom-search:focus-within {
  opacity: 1;
}
.product-stat {
  overflow: hidden;
  -webkit-transition: all 3s;
  transition: all 3s;
}
.product-stat h2 {
  padding: 3px 8px;
}
.bar-item-clickable {
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  padding: 3px 8px 9px 8px;
}
.bar-item-clickable:hover {
  background-color: #f7f7f7;
}
.bar-item-clickable.active {
  background-color: #1890ff;
  color: #fff;
}
.bar-progress {
  background-color: #3867d6;
  border-radius: 4px;
  height: 8px;
  -webkit-transition: width 1s;
  transition: width 1s;
}
.btn-collapse-chart {
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  opacity: 0.5;
  padding: 12px;
}
.btn-collapse-chart:hover {
  background-color: #e8e8e8;
  opacity: 1;
}
.btn-collapse-chart > svg {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
.webkit-box {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.two-lines {
  -webkit-line-clamp: 2;
}
.hoverable-65 {
  opacity: 0.65;
}
.hoverable-65:hover {
  opacity: 1;
}
.custom-markdown-sm img {
  max-height: 24px;
  max-width: 100%;
}
.plan-file {
  position: relative;
}
.plan-file .overlay-action {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  position: absolute;
  top: 12px;
  right: 12px;
}
.plan-file:hover .overlay-action {
  opacity: 1;
}
@media only screen and (min-width: 1600px) {
  .custom-available-list {
    height: calc(80vh - 340px - 21px);
  }
}
.ant-select-dropdown.width-auto {
  width: auto !important;
  min-width: 200px;
  max-width: 300px;
}
.ant-select-dropdown.width-auto2 {
  width: auto !important;
  min-width: 200px;
  max-width: 650px;
}
/* Override ant design style in here ----------- start */
.custom-table .ant-table-thead > tr > th {
  background: #ededed;
  border-bottom: 1px solid #c1c1c1;
}
.ant-table-tbody tr.odd {
  background-color: #f7f7f7;
}
.ant-table-tbody tr.row-group {
  background-color: #e6eff6;
}
.custom-table-sm .ant-table-tbody > tr > td {
  padding: 6px 8px;
}
.custom-table-sm .ant-table-thead > tr > th {
  padding: 12px;
}
.custom-ant-pie .antd-pro-charts-pie-legend {
  max-height: 100%;
  overflow-y: scroll;
}
.custom-ant-pie .antd-pro-charts-pie-legend li > .antd-pro-charts-pie-value {
  right: 8px;
}
.custom-table-small .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.calendar-group-center input {
  border-radius: 0;
}
.custom-calendar-small .ant-fullcalendar-cell > .ant-fullcalendar-date {
  height: 86px;
}
.custom-calendar-small .ant-fullcalendar-cell > .ant-fullcalendar-date > .ant-fullcalendar-content {
  height: 54px;
}
.custom-calendar-tomato .ant-fullcalendar-date.active,
.custom-calendar-tomato .ant-fullcalendar-month.active {
  background-color: tomato;
  border-top-color: #d42000 !important;
  color: white;
  font-weight: bold;
}
.custom-calendar-tomato .ant-fullcalendar-date.active .ant-fullcalendar-value,
.custom-calendar-tomato .ant-fullcalendar-month.active .ant-fullcalendar-value {
  color: white;
}
.custom-calendar-tomato .ant-fullcalendar-date.active:hover,
.custom-calendar-tomato .ant-fullcalendar-month.active:hover {
  background-color: #d0003a;
}
.custom-calendar-tomato .ant-fullcalendar-cell.ant-fullcalendar-selected-day > .ant-fullcalendar-date.active {
  background-color: #d0003a;
}
.es-feedback-md-textarea {
  max-height: 340px !important;
}
.parent-section-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0.4rem 0 0.4rem 1.25rem !important;
}
.parent-section-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 0.25rem !important;
}
.parent-section-collapse .ant-collapse-borderless {
  background-color: #fff !important;
}
.parent-section-collapse .ant-collapse-content-box {
  padding: 0 !important;
}
.parent-section-collapse li {
  padding: 1px 0 1px 8px;
}
.parent-section-collapse li:first-child {
  border-top: 1px solid #d9d9d9;
}
.parent-section-collapse li:hover {
  background-color: #e6f7ff;
  cursor: pointer;
}
.parent-section-collapse li.selected-subsection {
  background-color: lavender;
  color: #000;
  font-weight: 600;
}
.parent-section-collapse li .subsection-list-item {
  border-bottom: 1px solid #aaa;
  font-size: 13px;
  align-items: center-comment;
}
.section-collapse {
  margin: 12px 0;
}
.section-collapse .section-panel .ant-collapse-header {
  font-size: 13px;
  padding: 0.15rem 0 0.15rem 1.25rem !important;
}
.section-collapse .section-panel .ant-collapse-header .ant-collapse-arrow {
  left: 0.25rem !important;
}
.section-collapse .section-panel > .ant-collapse-content {
  color: rgba(0, 0, 0, 0.75);
}
.default-scroll::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 0;
}
.default-scroll::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 0;
}
.default-scroll::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 0;
}
.default-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #adadad;
}
.default-scroll::-webkit-scrollbar-thumb:active {
  background-color: #909090;
}
.custom-select-with-operator .ant-select-selection {
  padding-right: 88px;
  max-height: 58px;
  overflow-y: auto;
}
.wrapper-height-spin > .ant-spin-nested-loading,
.wrapper-height-spin > .ant-spin-nested-loading > .ant-spin-container {
  height: 100% !important;
}
/* Override ant design ------------------------- end */

